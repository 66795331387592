import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message, Spin } from 'antd';
import AppLayout from '../../components/layout';
import './Yard.css';
import List from './comp/List';
import WashList from './comp/WashList';
import CloseList from './comp/CloseList';
import API from '../../api';
import {
  getAssetList,
  getInYardList,
  getClosedOutList,
} from '../../actions/myYardAction';
import { Routes } from '../../utils/route';
import { usePosition } from '../../utils/usePosition';
import * as queryString from "query-string";
import { navigate } from 'gatsby-link';
import PageHeader from '../../components/pageHeader/PageHeader';

const MyYard = (props) => {
  const { latitude, longitude } = usePosition();
  const { assetList, approveAsset, InYardList, closedOut } = API;
  const [yardList, setYardList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inYardList, setInYardList] = useState([]);
  const [closeList, setCloseList] = useState([]);
  const [tab, onTab] = useState(0);


  useEffect(() => {
    const _yard = JSON.parse(localStorage.getItem('yard'));
    onTab(_yard || 0);
  }, []);

  useEffect(
    () => {
      localStorage.setItem('yard', tab);
      if(tab===0){
        props.getAssetList(0,'');
      }else if(tab===1){
        props.getInYardList(0,'');
      }else{
        props.getClosedOutList(0,'');
      }
    },
    [tab]
  );

  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
      }
    };
    Routes('manager', callback);
  }, []);

  const onPageChange=(page)=>{
    /**
    if(tab===0){
      props.getAssetList((page)*10,'');
    }else if(tab===1){
      props.getInYardList((page)*30,'');
    }else{
      props.getClosedOutList((page)*10,'');
    }**/

  }
  const inYardTableNav=(id)=>{
    onTab(id);
  }
  if (!loading) {
    return (
      <AppLayout>
        <PageHeader title="my yard"/>
        <section className="yard d-print-none">
               <div style={{display:tab===0?'block':'none'}}><WashList tab={tab} inYardTableNav={inYardTableNav} onPageChange={onPageChange}  loading={props.loadingAsset} yardList={props.assetList} count={props.assetCount} /></div>
               <div style={{display:tab===1?'block':'none'}}><List tab={tab} inYardTableNav={inYardTableNav} onPageChange={onPageChange} inYardList={props.inYardList} count={props.inYardCount} loading={props.loadingInYard} /></div>
               <div style={{display:tab===2?'block':'none'}}><CloseList isPickedUp={true} tab={tab} inYardTableNav={inYardTableNav}
                  onPageChange={onPageChange}
                  closeList={props.closedOutList}
                  count={props.closedOutCount}
                  loading={props.loadingClosedOut}
                /></div>

        </section>
      </AppLayout>
    );
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  loading: state.myYard.loading,
  loadingAsset:state.myYard.loadingAsset,
  assetList: state.myYard.assetList,
  assetCount: state.myYard.assetCount,
  loadingInYard:state.myYard.loadingInYard,
  inYardList: state.myYard.inYardList,
  inYardCount: state.myYard.inYardCount,
  loadingClosedOut:state.myYard.loadingClosedOut,
  closedOutList: state.myYard.closedOutList,
  closedOutCount: state.myYard.closedOutCount,
});

export default connect(mapStateToProps, {
  getAssetList,
  getInYardList,
  getClosedOutList,

})(MyYard);
