import React, { useState, useEffect } from "react"
import { Card, Table, Drawer, Button } from "antd"
import { EyeOutlined } from "@ant-design/icons"
import moment from "moment"
import "./CloseList.css"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import Invoice from "../invoice"
import Estimate from "../estimate"
import DataTable from "../../../components/data-display/table/DataTable"
import Certificate from "../certificate"
import Performa from "../performa/Performa"
import ActionButton from "../../../images/new/action_icon.svg"
import {
  FetchLocalData,
  IndustryType,
  NumToStr,
  TextCapitalize,
  TextUpperCase,
} from "../../../utils/helper"
const WashList = props => {
  const { count } = props
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [estimate, setEstimate] = useState(false)
  const [proforma, setProforma] = useState(false)
  const [certificate, setCertificate] = useState(false)
  const [invoice, setInvoice] = useState(false)
  const [drawerData, setDrawerData] = useState({})
  const [ticketStatus, setTicketStatus] = useState("")
  let tableColumns = []
  if (IndustryType() !== "Food_Grade") {
    tableColumns = [
      {
        name: "EQUIPMENT NO.",
        label: "Equipment No.",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextUpperCase(value),
        },
      },
      {
        name: "TICKET NO.",
        label: "Ticket No.",
        options: { filter: true, sort: true },
      },
      {
        name: "CERTIFICATE NO.",
        label: "Certificate No.",
        options: { filter: true, sort: true },
      },
      {
        name: "CUSTOMER",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextCapitalize(value),
        },
      },
      {
        name: "EQUIPMENT TYPE",
        label: "Equipment Type",
        options: { filter: true, sort: true, customBodyRender: value => value },
      },
      // {name: "CHECK IN",label: "CHECK IN",options: {filter: true,sort: true,}},
      // {name: "PO NUMBER",label: "PO NUMBER",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      // {name: "WASH STATUS",label: "WASH STATUS",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "ESTIMATE $$",label: "ESTIMATE $$",options: {filter: true,sort: true,customBodyRender: value =>'$'+NumToStr(value,0)}},
      // {name: "YARD LOCATION",label: "YARD LOCATION",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "FROM/GENERATOR",label: "FROM/GENERATOR",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "JOBSITE",label: "JOBSITE",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "DROP OFF CARRIER",label: "DROP OFF CARRIER",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "DROP OFF DRIVER",label: "DROP OFF DRIVER",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "DATE CLEANED",label: "DATE CLEANED",options: {filter: true,sort: true,}},
      // {name: "PICK UP DATE",label: "Pick up Date",options: {filter: true,sort: true,}},
      // {name: "PICK UP CARRIER",label: "Pick up Carrier",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      // {name: "PICK UP DRIVER",label: "Pick up Driver",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {
        name: "ACTION",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              right: "0",
              background: "#fff",
              zIndex: 100,
              textAlign: "center",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              right: 0,
              color: "white",
              // background: "#1890ffd1",
              zIndex: 101,
            },
          }),
        },
      },
    ]
  } else {
    tableColumns = [
      {
        name: "TRAILER UNIT NO.",
        label: "Trailer No.",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextUpperCase(value),
        },
      },
      {
        name: "TRUCK NO.",
        label: "Truck No.",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextUpperCase(value),
        },
      },
      {
        name: "TICKET NO.",
        label: "Ticket No.",
        options: { filter: true, sort: true },
      },
      {
        name: "CERTIFICATE NO.",
        label: "Certificate No.",
        options: { filter: true, sort: true },
      },
      {
        name: "CUSTOMER",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextCapitalize(value),
        },
      },
      {
        name: "PICK UP DATE",
        label: "Pick up Date",
        options: { filter: true, sort: true },
      },
      {
        name: "PICK UP DRIVER",
        label: "Pick up Driver",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => TextCapitalize(value),
        },
      },
      {
        name: "ACTION",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              right: "0",
              background: "#fff",
              zIndex: 100,
              textAlign: "center",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              right: 0,
              color: "white",
              // background: "#1890ffd1",
              zIndex: 101,
            },
          }),
        },
      },
    ]
  }

  const showDrawer = data => {
    setDrawerData(data)
    setTicketStatus(data.ticket_details?.status)
    setIsDrawerVisible(true)
  }

  const tableData = []
  props.closeList &&
    props.closeList.map((item, index) => {
      const carrierDrop = item.asset_txns_details?.filter((val, index) => {
        if (val.field_name === "trucking_carrier") {
          return val
        }
      })
      const generatorData = item.asset_txns_details?.filter((val, index) => {
        if (val.field_name === "generator") {
          return val
        }
      })
      const pickUpCarrier = item.asset_txns_details?.filter((val, index) => {
        if (val.field_name === "pickup_carrier") {
          return val
        }
      })
      const pickUpDriver = item.asset_txns_details?.filter((val, index) => {
        if (val.field_name === "pickup_driver_name") {
          return val
        }
      })

      const dateCleandData = item.ticket_details?.ticket_txns_details?.filter(
        (val, index) => {
          if (val.parameter_name === "ticket_completed") {
            return val
          }
        }
      )

      const PickUpDate = item.asset_txns_details?.filter((val, index) => {
        if (val.field_name === "equipment_checked_out") {
          return val
        } else if (val.field_value === "equipment_checked_out") {
          return val
        }
      })
      if (IndustryType() !== "Food_Grade") {
        tableData.push([
          item.asset_details.equipment_no,
          item.ticket_details?.ticket_ref_id ?? "-",
          item.ticket_details?.certificate_no ?? "-",
          item.customer_details?.name,
          FetchLocalData(
            item.asset_details?.equipment_type_details?.parent_id,
            item.asset_details?.equipment_type_details?.param_value
          ),
          //   item.check_in ? moment(item.check_in).format('MM/DD/YYYY') : '-',
          //   item.po_details?.po_no ?? '-',
          //   item.asset_txns_status?.param_description && item.asset_txns_status?.param_description === 'Checked-out' ?'PICKED-UP':'-',
          //   item.ticket_details?.estimate_price.toFixed(2),
          //   item.yard_details?.name ?? '-',
          //   generatorData && generatorData.length > 0 ? generatorData[0].field_value : '-',
          //   item.customer_details?.job_site ?? '-',
          //  carrierDrop && carrierDrop.length > 0 ? carrierDrop[0].field_value : '-',
          //  item.driver_name ?? '-',
          //  dateCleandData && dateCleandData.length > 0 ? moment(dateCleandData[0].created_at).format('MM/DD/YYYY') : '-',
          //  PickUpDate && PickUpDate.length > 0 ? moment(PickUpDate[0].created_at).format('MM/DD/YYYY') : '-',
          //  pickUpCarrier && pickUpCarrier.length > 0 ? pickUpCarrier[0].field_value : '-',
          //  pickUpDriver && pickUpDriver.length > 0 ? pickUpDriver[0].field_value : '-',
          <img
            src={ActionButton}
            alt="icon"
            onClick={() => showDrawer(item)}
            style={{ cursor: "pointer" }}
          />,
        ])
      } else {
        tableData.push([
          item.asset_details.equipment_no,
          item?.truck_number ?? "-",
          item.ticket_details?.ticket_ref_id ?? "-",
          item.ticket_details?.certificate_no ?? "-",
          item.customer_details?.name,
          PickUpDate && PickUpDate.length > 0
            ? moment(PickUpDate[0].created_at).format("MM/DD/YYYY")
            : "-",
          pickUpDriver && pickUpDriver.length > 0
            ? pickUpDriver[0].field_value
            : "-",
          <img
            src={ActionButton}
            alt="icon"
            onClick={() => showDrawer(item)}
            style={{ cursor: "pointer" }}
          />,
        ])
      }
    })
  // for (let i = 0; i < 100; i++) {
  //     data.push({
  //         key: i,
  //         name: `Edward King ${i}`,
  //         address: `London, Park Lane no. ${i}`,
  //     });
  // }
  const yardNav = [
    { id: 0, name: "pre wash" },
    { id: 1, name: "in yard" },
    { id: 2, name: "picked up" },
  ]
  return (
    <div>
      {/* <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 330 }}
      /> */}
      <DataTable
        loader={props.loading}
        onPageChange={props.onPageChange}
        count={props.count}
        urlParam="pickedUp"
        isPickedUp={props.isPickedUp}
        title={
          <div className="wash_nav_yard_section">
            {yardNav.map(item => {
              return (
                <span
                  id={item.id}
                  onClick={() => props.inYardTableNav(item.id)}
                  style={{
                    color: props.tab === item.id ? "#FD8F46" : "",
                    borderBottom:
                      props.tab === item.id ? "2px solid #FD8F46" : "",
                  }}
                >
                  {item.name}
                </span>
              )
            })}
          </div>
        }
        header={tableColumns}
        data={tableData}
        rowKey="id"
      />
      <Drawer
        className="drawer"
        title={<div className="wash_yard_drawer_head">wash status</div>}
        placement="right"
        // closable={false}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <div className="wash_yard_drawer_title">
            Equipment No:<span>{drawerData?.asset_details?.equipment_no}</span>
          </div>
          <div className="wash_yard_drawer_title">
            Wash Ticket:<span>{drawerData?.ticket_details?.ticket_ref_id}</span>
          </div>

          <div className="wash_yard_drawer_button">
            <div
              className="wash_yard_drawer_btn"
              onClick={() =>
                navigate(
                  `/ticketdetails/${drawerData?.ticket_details?.ticket_id}`
                )
              }
            >
              Ticket Details
            </div>

            {ticketStatus !== 3250 && (
              <>
                {ticketStatus !== 3250 && (
                  <>
                    <Certificate
                      visible={certificate}
                      setVisible={e => setCertificate(e)}
                      data={drawerData}
                      {...props}
                      Button={<span>Certificate</span>}
                      title="Certificate Of Washout"
                    />
                  </>
                )}
              </>
            )}
            {(ticketStatus === 3200 ||
              ticketStatus === 3225 ||
              ticketStatus === 3220) && (
              <>
                {/* <Performa
                  setTicketStatus={setTicketStatus}
                  visible={proforma}
                  setVisible={e => setProforma(e)}
                  data={drawerData}
                  {...props}
                  Button={<span>Estimate | Chargesheet</span>}
                  title="Estimate | Chargesheet"
                />**/}
                {ticketStatus === 3225 && (
                  <>
                    {" "}
                    <Invoice
                      visible={invoice}
                      setVisible={e => setInvoice(e)}
                      data={drawerData}
                      {...props}
                      Button={<span>Final Invoice</span>}
                      title="Final Invoice"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Drawer>
      {count > 100 ? (
        <span className="reportSection checkReportFont">
          To see more than 100 records, please check{" "}
          <Link to="/report">report</Link> section.
        </span>
      ) : (
        ""
      )}
    </div>
  )
}
export default WashList
